<div class="page-banner-bg banner-bg d-flex justify-content-center align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="page-banner-content">
                    <h1>What do people praise <br> about <span>our excellence!</span></h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-content overflow-hidden">
    <section class="section-space">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="testimonials-content">
                        <div class="testimonials-row text-center wow "data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"  >
                            <div class="testimonials-avater">
                                <img src="../../assets/testo/yashwantray.png" alt="">
                            </div>
                            <h4>Yashwant Ray</h4>
                            <span>Diamond Portal</span>
                            <div class="testimonials-info mt-4">
                                <p>Diamond Nexus is a product that puts customers first. They support customer needs. It perfectly do justice to its tag line "We build satisfaction, not just a product!"</p>
                            </div>
                        </div>
                        <div class="testimonials-row text-center wow " data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom">
                            <div class="testimonials-avater">
                                <img src="../../assets/testo/johnmedison.png" alt="">
                            </div>
                            <h4>John Madison</h4>
                            <span>Diamond Portal</span>
                            <div class="testimonials-info mt-4">
                                <p>Diamond Nexus is a wonderful product to have for the diamond company. The majority of people who work here love the software. There are many people here that have 10 plus years in already and they fill the best software till date they have used. I believe with the seriously high cost of living now that we are just not paid high enough especially if you are a
                                    single individual. The cost of living just keeps going up and up and up and this product worth everything.</p>
                            </div>
                        </div>
                        <div class="testimonials-row text-center wow" >
                            <div class="testimonials-avater">
                                <img src="../../assets/testo/manohar kumar.png" alt="">
                            </div>
                            <h4>Manohar Kumar</h4>
                            <span>Diamond Portal</span>
                            <div class="testimonials-info mt-4">
                                <p>Diamond Nexus is a perfect software for our organisation, and it has everything that wee need. The best part is the on-time service and world class service that we ever had before. Most most recommended!!!</p>
                            </div>
                        </div>
                        <div class="testimonials-row text-center wow " >
                            <div class="testimonials-avater">
                                <img src="../../assets/testo/piyushmehra.png" alt="">
                            </div>
                            <h4>Piyush Mehra</h4>
                            <span>Diamond Portal</span>
                            <div class="testimonials-info mt-4">
                                <p>Just one word - UNBELIEVABLY AWESOME! Don't think for twice, just go for it and you never regret it for sure.</p>
                            </div>
                        </div>

                    </div>
                    <div class="mt-4 text-center" data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom">
                        <a href="javascrpit:;" class="theme-btn">View More</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-parallax></app-parallax>
</div>