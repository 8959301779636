import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import * as Aos from 'aos';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { RfxParallaxService } from 'rfx-parallax';
import { ApiServiceService } from 'src/app/api/api-service.service';
// import { ng2parallax  } from '../../../../node_modules/ang2-parallax/ng2-parallax-directive/parallax.directive';
import { ng2Parallax  } from '../../../../node_modules/ang2-parallax/ng2parallax';
import simpleParallax from 'simple-parallax-js';

export interface IProperity{
  Id:number,
  url:string;
  title:string;
  description:string;
}
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  state = false;
  isLoading:boolean=true;
  startIndex?: number;
  // collection: any;
  // services:any;
  constructor(public el: ElementRef, private http: ApiServiceService, private rfxParallaxService: RfxParallaxService) { 
  }
  collection = [
    {
      id: 1,
      url: '../../../assets/discover.svg',
      title: 'Discover',
      description: 'We shape brands through exploration, applying in-depth research to challenge assumptions at every turn.',
    },
    {
      id: 2,
      url: '../../../assets/design.svg',
      title: 'Design',
      description: 'Our design approach is to simplify. We embrace the joy in creating something unique that is easy for end users.',
    },
    {
      id: 3,
      url: '../../../assets/build.svg',
      title: 'Build',
      description: 'Using modern technologies, we build with efficiency and skill, creating flexible and scalable business-driven solutions.',
    },
    {
      id: 4,
      url: '../../../assets/deliver.svg',
      title: 'Deliver',
      description: 'We take an iterative approach to both our work and our practice, always looking for ways to improve what we do.',
    }
  ];
  services = [
    {
      url: '../../../assets/web.svg',
      title: 'Website Development',
      description: 'We have expertise in developing CMS site with Angular, ReactJS and VueJS. Good experience of developing E-commerce Stores that we develop with Laravel and Magento.',
    },
    {
      url: '../../../assets/mobile.svg',
      title: 'Mobile App Development',
      description: 'We develop Android and iOS applications. We combine mobile apps along with our full package of the diamond product to have seamless access to the data. We also build e-commerce applications for online shopping system.',
    },
    {
      url: '../../../assets/uiux.svg',
      title: 'UI/UX Design',
      description: 'We have extra-ordinary team to make wireframes of product to get starting with full speed. We use latest technology to build and showcase design.',
    },
    {
      url: '../../../assets/CMS.svg',
      title: 'CMS & CRM',
      description: 'We build complete CMS/CRM product of custom requirements. We combine multiple systems along with our full package of the diamond product to have seamless access to the data.',
    },
    {
      url: '../../../assets/CRM.svg',
      title: 'ERP System',
      description: 'We build seamless ERP system for small and big businesses, it includes custom reports and highly customisable dashboard for one stop view of current business.',
    },
    {
      url: '../../../assets/graphic.svg',
      title: 'Graphics & Logo Designing',
      description: 'We have highly skilled and extremely creative designers to design unique and never-seen-before graphics and logos.',
    }
  ];
  ngOnInit() {
    this.rfxParallaxService.initListeners();
    Aos.init();
    // const image = document.getElementsByClassName('bg');
    //  new simpleParallax(image,{
    //     scale: 1.5,
    //   });  
  }
  customOptions: OwlOptions = {
    // stagePadding: -70,
    margin: 50,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    autoplay: true,
    pullDrag: false,
    dots: false,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      // 740: {
      //   items: 3
      // },
      // 1000: {
      //   items: 4
      // }
    },
    nav: false
  }
}
