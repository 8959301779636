<div class="page-banner-bg banner-bg d-flex justify-content-center align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="page-banner-content">
                    <h1>About Us</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mt-3">
                            <li class="breadcrumb-item"><a href="#">Company</a></li>
                            <li class="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-content">
    <section class="section-space">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 wow" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                    style="visibility: visible;">
                    <div class="about-content mb-4">
                        <h3>Ensure results today and readiness tomorrow.</h3>
                        <p>We are leading the Diamond industry's IT movement into tomorrow‘s value-enabled world. No
                            other IT partner integrates your information so you can take action across all your
                            products, apps and even across the website in real-time sync. Imagine the value of enabling
                            multiple product integrations – without having to rip-and-replace or rework any of your
                            systems.</p>
                        <p>We‘re pioneering comprehensive diamond process solutions and integrating across the different
                            processes. We partner with you to enable smarter diamond process delivery. We also work with
                            CMS and CRM systems.</p>
                        <p>Take a closer look at ProLance IT. Experience how our Manufacturing, Selling (Certified +
                            Loose), Financial Account, Website and Application solutions can give you connected
                            information and insights like never before. It‘s everything you need to confidently meet
                            your clinical, operational and business goals.
                        </p>

                    </div>
                    <div class="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <div class="col-md-6">
                            <div class="about-our-content">
                                <h5>Our <span class="theme-t-yellow-clr">Mission</span></h5>
                                <p>To eliminate people's hard work by smart work using our great Diamond ERP System.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="about-our-content">
                                <h5>Our <span class="theme-t-yellow-clr">Vision</span></h5>
                                <p>Ensure results today and readiness tomorrow.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-our-img mt-4 mt-lg-0 wow fadeInUp" data-wow-duration="0.5s" data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom">
                        <img src="../../../assets/about-us/about-1.png" class="img-fluid w-100" alt="">
                    </div>
                    <div class="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <div class="col-md-6 wow fadeInUp" data-wow-duration="1s">
                            <div class="about-our-img mt-4">
                                <img src="../../../assets/about-us/about-2.png" class="img-fluid w-100" alt="">
                            </div>
                        </div>
                        <div class="col-md-6 wow fadeInUp mt-4" data-wow-duration="1.5s">
                            <div class="about-our-img">
                                <img src="../../../assets/about-us/about-3.png" class="img-fluid w-100" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="curve-bg" style="bottom:-2px" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <img src="../../../assets/curve-1.svg" class="img-fluid" alt="">
    </div>
    <section class="work-space light-bg position-relative ">

        <div class="pattern-part pattern-2"></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 wow " data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    <img src="../../../assets/about-us/how-started.png" class="img-fluid w-100" alt="How we started">
                </div>
                <div class="col-lg-6 wow " data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    <div class="services-nameinfo mt-4 mt-lg-0">
                        <h2>Who we are</h2>
                        <p>We live by our vision, and improving people’s working life starts with our own company and
                            products. We’re building a platform and products we believe in – as well as a strong,
                            diverse team of enthusiastic, passionate, creative people who want to do the best work and
                            support each other in the process. </p>
                        <p>Our leadership includes some of the most inventive and experienced executives in the
                            technology industry. </p>
                        <p>Careers at ProLance IT offer all kinds of opportunities and a simpler, more pleasant, more
                            productives working life. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-parallax></app-parallax>
</div>