import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import simpleParallax from 'simple-parallax-js';

@Component({
  selector: 'app-parallax',
  templateUrl: './parallax.component.html',
  styleUrls: ['./parallax.component.css']
})
export class ParallaxComponent implements OnInit, OnDestroy {
  instance:any;
  constructor(private elementRef: ElementRef, private route: Router) {
    this.route.events.subscribe(RouterEvent => {
      if (RouterEvent instanceof NavigationEnd ) {
        // window.location.reload();
      }
    });
   }
  ngOnInit(): void {
    const image = document.getElementsByClassName('bg');
    this.instance= new simpleParallax(image,{
        scale: 1.5,
        // overflow:true
      });  
    }
    ngOnDestroy(){
    }
}
