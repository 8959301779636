import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators, MinLengthValidator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from '../api/api-service.service';

export interface mail {
  // url: string;
  // title: string;
  // description: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  myForm: any;
  submit: boolean = false;
  public response: any;
  btnDisable?: boolean;
  constructor(private fb: FormBuilder, private http: HttpClient, private toastr:ToastrService) { }
  Email: any;
  message: any = "this is done";
  ngOnInit(): void {
    this.myForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
    });
  }
  get getControl() {
    return this.myForm.controls;
  }
  async saveForm(): Promise<any> {
    this.submit = true;
    if (this.myForm.valid) {
      this.btnDisable=true;
      this.showWaiting();
      const mailData = new FormData();
      mailData.append("name", this.myForm.controls.name.value);
      mailData.append("email", this.myForm.controls.email.value);
      mailData.append("message", this.myForm.controls.message.value);
      this.response = await this.http.post('https://api.prolanceit.in/email/send', mailData).toPromise();
      this.showSuccess()
      this.myForm.reset({});
      this.submit = false;
      this.btnDisable=false;
    }
  }
  showWaiting() {
    this.toastr.warning('Sending Request...',"",{
      timeOut: 30000,
    });
  }
  showSuccess() {
    this.toastr.success('Request Sent! We Will Contact You Soon. Thank You!');
  }
}
