<div class="curve-bg" style="bottom:-2px">
    <img src="../../../assets/curve-1.svg" class="img-fluid" alt="">
</div>
<section class="work-space light-bg position-relative pb-4">
    <div class="container" >
        <div class="section-title text-center wow fadeInUp" 
        ata-aos="fade-up"
        data-aos-duration="3000">
            <h2>Managed IT services <br> for your <span>Industries!</span></h2>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4 col-xl-3 wow "  
                data-aos="fade-up"
            data-aos-anchor-placement="top-bottom">
                <div class="industries-box">
                    <div class="industries-boxImg overflow-hidden">
                        <img src="../../../assets/about-us/about-3.png" class="img-fluid" alt="">
                    </div>
                    <div class="industries-content">
                        <h4 class="services-title mb-4">Diamond Industry</h4>
                        <ul class="list-unstyled">
                            <li>UI/UX Design</li>
                            <li>Front End Development</li>
                            <li>API Integration</li>
                            <li>Payment Gateway</li>
                            <li>Cyber Security</li>
                            <li>Data Management</li>
                            <li>Hosting &amp; Cloud</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"  data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
               >
                <div class="industries-box">
                    <div class="industries-boxImg overflow-hidden">
                        <img src="../../../assets/about-us/about-2.png" class="img-fluid" alt="">
                    </div>
                    <div class="industries-content">
                        <h4 class="services-title mb-4">E-Commerce</h4>
                        <ul class="list-unstyled">
                            <li>UI/UX Design</li>
                            <li>Front End Development</li>
                            <li>API Integration</li>
                            <li>Payment Gateway</li>
                            <li>Cyber Security</li>
                            <li>Data Management</li>
                            <li>Hosting &amp; Cloud</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"  data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
                >
                <div class="industries-box">
                    <div class="industries-boxImg overflow-hidden">
                        <img src="../../../assets/about-us/about-3.png" class="img-fluid" alt="">
                    </div>
                    <div class="industries-content">
                        <h4 class="services-title mb-4">Real Estate</h4>
                        <ul class="list-unstyled">
                            <li>UI/UX Design</li>
                            <li>Front End Development</li>
                            <li>API Integration</li>
                            <li>Payment Gateway</li>
                            <li>Cyber Security</li>
                            <li>Data Management</li>
                            <li>Hosting &amp; Cloud</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"  
                data-aos="fade-up"
            data-aos-anchor-placement="top-bottom">
                <div class="industries-box">
                    <div class="industries-boxImg overflow-hidden">
                        <img src="../../../assets/about-us/diamond.png" class="img-fluid" alt="">
                    </div>
                    <div class="industries-content">
                        <h4 class="services-title mb-4">Health Industry</h4>
                        <ul class="list-unstyled">
                            <li>UI/UX Design</li>
                            <li>Front End Development</li>
                            <li>API Integration</li>
                            <li>Payment Gateway</li>
                            <li>Cyber Security</li>
                            <li>Data Management</li>
                            <li>Hosting &amp; Cloud</li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- <app-parallax></app-parallax> -->