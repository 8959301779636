<section class=" parallax-window business-contact  text-center d-flex justify-content-center align-items-center position-relative"
    libRfxParallax PositionPercentage= "50"   imageUrl="../../../assets/business-contact.png">
    <div class="container">
        <h6>Are you ready for a better,</h6>
        <h2>More productive Business?</h2>
        <a [routerLink]="['/contact-us']" class="theme-btn bg-theme-yellow-btn">Contact Us Now</a>
    </div>
    
</section>
<!-- <section class=" parallax-window business-contact  text-center d-flex justify-content-center align-items-center 
    position-relative overflow-hidden ">
    <img src="../../../assets/business-contact.png">
    <div class="container position-absolute">
        <h6>Are you ready for a better,</h6>
        <h2>More productive Business?</h2>
        <a [routerLink]="['/contact-us']" class="theme-btn bg-theme-yellow-btn">Contact Us Now</a>
    </div>
    <div>
        <img class="bg" src="../../../assets/business-contact.png">
    </div>
</section> -->