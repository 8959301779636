<footer>
    <div class="footer-bottom " libRfxParallax PositionPercentage= "50"   imageUrl="../../../assets/footer-bg.png" >

        <div class="container">
            <div class="row">
                <div class="col-md-12 col-xl-4" >
                    <div class="footer-col mb-5 mb-xl-0">
                        <div class="logo-part">
                            <a [routerLink]="['/']"  class="footer-logo mb-5">
                                <img src="../../../assets/logo_footer.svg" class="img-fluid" alt="logos">
                            </a>
                            <p class="text-white">We are leading the Diamond industry's IT movement into tomorrow‘s value-enabled world. We develop full ERP system for Diamond industry, named as Diamond Nexus. We also make mobile apps, web apps and system based on custom requirement.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3 offset-xl-1">
                    <div class="footer-col mb-4">
                        <h2 class="footer-title orange">Quick Links</h2>
                        <div class="row">
                            <div class="col-sm-6">
                                <ul class="footer-menu list-unstyled">
                                    <li ><a [routerLink]="['/services']"  routerLinkActive="router-link-active" >Services</a></li>
                                    <li><a [routerLink]="['/technologies']"  routerLinkActive="router-link-active" >Technologies</a></li>
                                    <li><a [routerLink]="['/testimonials']"  routerLinkActive="router-link-active" >Testimonials</a></li>
                                    <li><a [routerLink]="['/contact-us']"  routerLinkActive="router-link-active" >Contact Us</a></li>
                                </ul>
                            </div>
                            <div class="col-sm-6">
                                <ul class="footer-menu list-unstyled">
                                    <li><a [routerLink]="['/about-us']"  routerLinkActive="router-link-active"  >About Us </a></li>
                                    <li><a [routerLink]="['/hiring']"  routerLinkActive="router-link-active" >Hiring</a></li>
                                    <li><a [routerLink]="['/our-product']"  routerLinkActive="router-link-active" >Our Product</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3 offset-xl-1">
                    <div class="footer-col">
                        <h2 class="footer-title orange">Contact Us</h2>
                        <ul class="footer-info list-unstyled">
                            <li>
                                <a href="mailto:it.prolance@gmail.com">
                                    <span><img src="../../../assets/social-image/email.svg" alt=""></span>
                                    it.prolance@gmail.com
                                </a>
                            </li>
                            <li>
                                <a href="https://www.google.com/maps/place/ProLance+IT/@21.2074589,72.8506096,13.19z/data=!4m5!3m4!1s0x0:0xe9c3c9c5dfa520c6!8m2!3d21.2048313!4d72.8488085" target="_blank">
                                    <span><img src="../../../assets/social-image/pin.svg" alt=""></span>
                                    Jay Shree Building, 2nd Floor, 34,35,36 Nandeshwari Society, Nr. Vasant Bhikha ni Wadi, L.H. Road, Surat, Gujarat 395006
                                </a>
                            </li>
                        </ul>
                        <ul class=" list-unstyled footer-social-icon d-flex align-items-center mt-4">
                            <li><a href="#" ><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" ><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" ><i class="fab fa-youtube"></i></a></li>
                            <li><a href="#" target="_blank" ><i class="fab fa-linkedin"></i></a></li>
                            <li><a href="#" ><i class="fab fa-google-plus-g"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="jarallax-container-1 logic">
            <div class="logic1">
            </div>
        </div>

    </div>
    <div class="footer-copyright">
        <div class="container">
            <p class="text-white text-center">Copyright © 2020 ProLance IT</p>
        </div>
    </div>
</footer>
