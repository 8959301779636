import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {delay, map} from 'rxjs/operators';
import { IProperity } from '../home/banner/banner.component';
import { mail } from '../contact/contact.component';
@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(private http:HttpClient) { }
  getCollection():Observable<IProperity[]>{
    return this.http.get<IProperity[]>('data/bannerCollection.json');
  }
  getServices():Observable<IProperity[]>{
    return this.http.get<IProperity[]>('data/bannerServices.json');
  }
  sendMail(mailData:any): Observable<any> {
    
    return this.http.post<any>("https://api.prolanceit.in/email/send", mailData)
  }
}