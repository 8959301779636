<app-loader [isLoading]='isLoading'></app-loader>
<div *ngIf="!isLoading">
    <header class="header-wrapper header-nosticky" (clickOutside)="onClickedOutside($event)" id="scroll-top" #target>
        <div class="container">
            <div class="header-part d-flex justify-content-between align-items-center">
                <div class="logo-part">
                    <a [routerLink]="['/']" routerLinkActive="router-link-active">
                        <img src="../assets/logo.svg" class="img-fluid" alt="">
                    </a>
                </div>

                <div class="menu-part" #target [ngClass]="{right: right}">
                    <a href="javascript:;" class="close-menu d-block d-lg-none" (click)="toggleBtn()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path fill="#FE9801"
                                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                                class=""></path>
                        </svg>
                    </a>
                    
                    <nav class="nav-bar d-flex align-items-center">
                        <ul class="d-lg-flex list-unstyled m-0">
                            <li><a [routerLink]="['/services']" routerLinkActive="active">Services</a></li>
                            <li><a [routerLink]="['/technologies']" routerLinkActive="active">Technologies</a></li>
                            <li><a [routerLink]="['/testimonials']" routerLinkActive="active">Testimonials</a></li>
                            <li class="dropdown">
                                <a class="dropbtn" [ngClass]="{active:activeContact }">Company</a>
                                <div class="dropdown-content">
                                    <a class="dropdown-item" [routerLink]="['/about-us']"
                                        routerLinkActive="active">About Us</a>
                                    <a class="dropdown-item" [routerLink]="['/hiring']"
                                        routerLinkActive="active">Hiring</a>
                                    <a class="dropdown-item" [routerLink]="['/our-product']"
                                        routerLinkActive="active">Our Product</a>
                                </div>
                            </li>
                            <!-- <li class="dropdown">
                                <a class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Dropdown button
                                </a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a class="dropdown-item" href="#">Action</a>
                                  <a class="dropdown-item" href="#">Another action</a>
                                  <a class="dropdown-item" href="#">Something else here</a>
                                </div>
                            </li> -->
                        </ul>
                        <ul class="account-right d-none d-lg-block list-unstyled  m-0">
                            <li>
                                <a [routerLink]="['/contact-us']" class="account-btn-bg">Contact Us</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="d-flex d-lg-none align-items-center" #toggle>
                    <ul class="account-right list-unstyled m-0">
                        <li>
                            <a href="javascript:;" [routerLink]="['/contact-us']" class="account-btn-bg">
                                <span class="d-none d-md-block">Contact Us</span>
                                <i class="fas fa-phone-alt d-block d-md-none"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="toggle-btn d-lg-none" (click)="toggleBtn()">
                        <a href="javascript:;">
                            <i></i>
                            <i></i>
                            <i></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div>
        <!-- <app-banner></app-banner> -->
        <router-outlet></router-outlet>

        <app-footer></app-footer>
        <a class="scrollup show" (click)="scrollToTop()" *ngIf="pageYoffset > 200px" data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"><span class="fas fa-arrow-up"></span></a>
        <div class="overlay-bg" [ngClass]="{opacity: right}"></div>
    </div>