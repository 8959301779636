import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.css']
})
export class HiringComponent implements OnInit {
  form: any;
  file:any;
  fileName:string="";
  submit?: boolean;
  btnDisable?:boolean;
  response: any;
  constructor(private fb:FormBuilder,private http:HttpClient, private toastr:ToastrService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
      importFile: ['', [Validators.required]]
    });
  } 
  uploadFile(event:any) {
    if (event.target.files.length > 0) {
    this.file = event.target.files[0];
    console.warn(event.target.files[0]);
    console.log(this.file.name);
      this.fileName=this.file.name;
      // this.form.patchValue({
      //   importFile: file
      // });
    }
  }

  async saveData(){
    this.submit=true;
    if(this.form.valid){
      this.btnDisable=true;
      this.showWaiting();
      var formData: any = new FormData();
      formData.append("name", this.form.controls.name.value);
      formData.append("email", this.form.controls.email.value);
      formData.append("message", this.form.controls.message.value);
      formData.append("file", this.file);
      this.response = await this.http.post('https://api.prolanceit.in/email/send', formData).toPromise();
      // console.log(this.response);
      this.showSuccess();
      // this.toastr.warning(this.response);
      this.fileName="";
      this.form.reset({});
      this.submit=false;
      this.btnDisable=false;
    }
  }
  showWaiting() {
    this.toastr.warning('Sending Request...',"",{
      timeOut: 30000,
    });
  }
  showSuccess() {
    this.toastr.success('Request Sent! We Will Contact You Soon. Thank You!');
  }
  serviceData = [
    {
      id: 1,
      img: "../../assets/dot_net.svg",
      position: "Dot Net Developer",
    },
    {
      id: 2,
      img: "../../assets/angular.svg",
      position: "Angular Developer",
    },
    {
      id: 3,
      img: "../../assets/vue-js.svg",
      position: "UI/UX Developer",
    },
    {
      id: 4,
      img: "../../assets/react-native.svg",
      position: "React_Native ",
    },
    // {
    //   id: 5,
    //   img: "../../assets/bootstrap.svg",
    //   position: "Andriod Developer",
    // },

  ]
  tabData = [
    {
      id: 1,
      hire:"We have urgent and exciting opening for Dot Net Core and Bootstrap 4 Developer in Surat, Gujarat, India.",
      img: "dot-net.jpg",
      opening: "2",
      title: "Dot Net Developer",
      exp: "1",
      skill: "Dot Net Core, Bootstrap, JQuery, PWA",
      resp: "Develop new user-facing features Build reusable code and libraries for future use Ensure the technical feasibility of UI/ UX designs Optimize application for maximum speed and scalability Assure that all user input is validated before submitting to back - end Super responsive designs Angular 7 + must needed and at least 1 year of experience Collaborate with other team members",
      requir: "Any graduate / post graduate in IT preferable MCA / Msc IT ME IT / M.Tech IT/ B.E. IT / B.E. Computer.",

    },
    {
      id: 2,
      hire:"We have urgent and exciting opening for Angular 7+ and Bootstrap 4 Developer in Surat, Gujarat, India.",
      img: "angular.jpg",
      title: "Angular Developer",
      opening: "3",
      exp: "2",
      skill: "ANgular 2+, Bootstrap, JQuery",
      resp: "Develop new user-facing features Build reusable code and libraries for future use Ensure the technical feasibility of UI/ UX designs Optimize application for maximum speed and scalability Assure that all user input is validated before submitting to back - end Super responsive designs Angular 7 + must needed and at least 1 year of experience Collaborate with other team members",
      requir: "Any graduate / post graduate in IT preferable BCA / MCA / Msc IT ME IT / M.Tech IT/ B.E. IT / B.E. Computer.",

    },
    {
      id: 3,
      img: "vue-js.jpg",
      hire:"We have urgent and exciting opening for UI/UX Designer in Surat, Gujarat, India.",
      title: "UI/UX Developer",
      opening: "1",
      exp: "1",
      skill: "Dot Net Core, Bootstrap, JQuery, PWA",
      resp: "Develop new user-facing features Build reusable Design and libraries for future use Ensure the technical feasibility of UI/ UX designs Optimize application for maximum speed and scalability Assure that all user input is validated before submitting to back - end Super responsive designs Angular 7 + must needed and at least 1 year of experience Collaborate with other team members",
      requir: "Any graduate / post graduate in IT preferable .",

    },
    {
      id: 4,
      img: "react-native.jpg",
      hire:"We have urgent and exciting opening for React-Native Developer in Surat, Gujarat, India.",
      title: "React-native Developer",
      opening: "3",
      exp: "2",
      skill: "React Native,React-Js, Bootstrap, JQuery,",
      resp: "Develop new user-facing features Build reusable code and libraries for future use Ensure the technical feasibility of UI/ UX designs Optimize application for maximum speed and scalability Assure that all user input is validated before submitting to back - end Super responsive designs Angular 7 + must needed and at least 1 year of experience Collaborate with other team members",
      requir: "Any graduate / post graduate in IT preferable BCA / MCA / Msc IT ME IT / M.Tech IT/ B.E. IT / B.E. Computer.",

    },
    // {
    //   id: 5,
    //   img: "bootstrap.jpg",
    //   title: "Andriod Developer",
    // },
  ]
  tabId: number = 0;
  tabClick(e: number) {
    console.warn(e);
    this.tabId = e;
  }
  customOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    autoHeight: true,
    autoWidth: false,
    rtl: false,
    dots: false,
    margin: 30,
    navText: ['<', '>'],
    lazyLoad: false,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 4
      },
      740: {
        items: 5
      },
      940: {
        items: 7
      }
    },
    nav: true
  }
}
