<div class="page-banner-bg banner-bg d-flex justify-content-center align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="page-banner-content">
                    <h1>Our Product</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mt-3">
                            <li class="breadcrumb-item"><a href="#">Company</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Our Product</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-content">
    <section class="section-space">
        <div class="container">
            <div class="section-title text-center wow" data-aos="fade-up"
            data-aos-anchor-placement="top-bottom">
                <h2>Diamond <span>Nexus</span></h2>
            </div>
            <div class="row flex-row-reverse">
                <div class="col-lg-6 col-xl-6 offset-xl-1 wow " data-aos="fade-up"
                data-aos-anchor-placement="top-bottom">
                    <div class="about-content mb-4">
                        <h3>The Next Generation of. <span class="theme-t-yellow-clr">Diamond</span> Software.</h3>
                        <p> We are leading the Diamond industry's IT movement into tomorrow‘s value-enabled world. No other IT partner integrates your information so you can take action across all your products, apps and even across the website in real-time sync. Imagine the value of enabling multiple product integrations – without having to rip-and-replace or rework any of your systems. </p>
                        <p> We‘re pioneering comprehensive diamond process solutions and integrating across the different processes. We partner with you to enable smarter diamond process delivery. We also work with CMS and CRM systems. </p>
                        <p> Take a closer look at ProLance IT. Experience how our Manufacturing, Selling (Certified + Loose), Financial Account, Website and Application solutions can give you connected information and insights like never before. It‘s everything you need to confidently meet your clinical, operational and business goals.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="industries-content p-0 mb-4">
                                <ul class="list-unstyled">
                                    <li>Easy maintenance of certified diamond inventory.</li>
                                    <li>Extract result sets (In-house grading including GIA and other labs).</li>
                                    <li>Management of Sales &amp; Memo transactions.</li>
                                    <li>Complete history of every single stone (GIA, IGI, HRD, NGT, etc.) with a complete track.</li>
                                    <li>Managing Stock of business process (like memo, consignment, etc).</li>
                                    <li>Always synced With Rapaport Price &amp; your website, it is immediately synced &amp; implements directly on the stock.</li>
                                    <li>Stock Tally using Barcode from any stage of Diamond.</li>
                                    <li>Upload Stock With ease, directly to Rapnet.</li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="industries-content p-0">
                                <ul class="list-unstyled">
                                    <li>FTP stock sharing included. (There is no web-based solution for stock sharing in this agreement.)</li>
                                    <li>User Authentication and Authorisation with system-level role access management.</li>
                                    <li>Pricing Analysis Module for better pricing management.</li>
                                    <li>Centralise Daily Stock Report on the dashboard.</li>
                                    <li>Advance Chart/Graph Reports (Day, Size and Shape wise report, e.g. Dossier, carat-up, fancy shapes, fancy colour, etc.)</li>
                                    <li>Fast Stock Tally using RFID (7000+ Diamonds under 20 minutes!).</li>
                                    <li>Buying average feature that allows user to check the average price of the stone before buying</li>
                                    <li>Export all invoice.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-5">
                    <div class="about-our-img mt-4 mt-lg-0 wow " data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom" style="visibility: visible; animation-duration: 0.5s; animation-name: fadeInUp;">
                        <img src="../../../assets/about-us/diamond.png" class="img-fluid w-100" alt="">
                    </div>
                    <div class="row">
                        <div class="col-md-6 wow " data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
                            <div class="about-our-img mt-4">
                                <img src="../../../assets/about-us/dia-2.jpg" class="img-fluid w-100" alt="">
                            </div>
                        </div>
                        <div class="col-md-6 wow  mt-4" data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" style="visibility: visible; animation-duration: 1.5s; animation-name: fadeInUp;">
                            <div class="about-our-img">
                                <img src="../../../assets/about-us/dia-3.jpg" class="img-fluid w-100" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-parallax></app-parallax>
</div>