<div class="page-banner-bg banner-bg d-flex justify-content-center align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="page-banner-content" data-aos="fade-up"
                data-aos-anchor-placement="top-bottom">
                    <h1>Now Hiring</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mt-3">
                            <li class="breadcrumb-item"><a href="#">Company</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Now Hiring</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-content">
    <section class="section-space">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="services-listing owl-carousel owl-loaded owl-drag">

                        <!-- <div class="owl-stage-outer">
                            <div class="owl-stage"
                                style="transform: translate3d(0px, 0px, 0px); transition: all 0s ease 0s; width: 237px;">
                                <div class="owl-item active" style="width: 221.25px; margin-right: 15px;">
                                    <div class="services-item">
                                        <a class="nav-item nav-link active" id="dot-net-developer-tab"
                                            href="javascript:;">
                                            <div class="circle-icon mb-5">
                                                <span><img src="../../../assets/angular.svg" alt="Angularz"></span>
                                            </div>
                                            <h3 class="icon-title">Angular Developer <span
                                                    class="hiring-openings my-2">3 Openings</span></h3>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <owl-carousel-o [options]="customOptions"
                        >
                            <ng-template carouselSlide *ngFor="let service of serviceData let i = index" >
                                <div class="services-item ">
                                    <a class="nav-link" (click)="tabClick(i)" [ngClass]="{active:tabId===i }">
                                        <div class="circle-icon mb-5 d-flex justify-content-center align-items-center">
                                            <span>
                                                <img src="{{service.img}}" alt=".NET">
                                            </span>
                                        </div>
                                        <h3 class="icon-title">
                                            {{service.position}}
                                            <span class="hiring-openings my-2">
                                                3 Openings
                                            </span>
                                        </h3>
                                    </a>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class=" services-tab-content position-relative overflow-hidden wow"  *ngFor="let tab of tabData let i= index">
        <div class="tab-pane fade show " *ngIf="tabId===i"  >
            <section class="section-space pt-0">
                <div class="container">
                    <div class="row flex-row-reverse">
                        <div class="col-lg-6">
                            <img src="../../../assets/programming-amico.svg" class="img-fluid" alt="Programming Amico">
                        </div>
                        <div class="col-lg-6">
                            <div class="services-nameinfo mt-4 mt-lg-0">
                                <h2>{{tab.title}}</h2>
                                <p class="font-weight-normal">{{tab.hire}} </p>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="hiring-list mb-5">
                                        <h3>{{tab.opening}}</h3>
                                        <p>Openings</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="hiring-list mb-5">
                                        <h3> {{tab.exp}} </h3>
                                        <p>Years of Experience</p>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="hiring-list mb-5">
                                        <h3>Key Skills</h3>
                                        <p>{{tab.skill}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="hiring-list mb-5">
                                        <h3>Responsibilities</h3>
                                        <p>{{tab.resp}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="hiring-list mb-5">
                                        <h3>Requirements</h3>
                                        <p>{{tab.requir}}</p>
                                    </div>
                                </div>
                                 <div class="col-12"> 
                                    <div class="hiring-bm">
                                        <p>Wan to apply now?</p>
                                        <hr>
                                        <div class="contact-form">
                                            <form [formGroup]="form" (ngSubmit)="saveData()">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>Name</label>
                                                        <div class="form-group mb-4">
                                                            <input type="text" placeholder="Enter Your Name"
                                                                class="form-control"  [ngClass]="{'red-border': form.get('name')?.errors && submit}" formControlName="name">
                                                            <i class="far fa-user"></i>
                                                            <span  [ngClass]="{show: form.get('name')?.errors && form.get('name')?.invalid && submit}" class="text-danger hide">
                                                                Valid Name is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>Email</label>
                                                        <div class="form-group mb-4">
                                                            <input type="text" formControlName="email"  [ngClass]="{'red-border': form.get('email')?.errors && submit}" placeholder="Enter Your Email"
                                                                class="form-control">
                                                            <i class="far fa-envelope"></i>
                                                            <span  [ngClass]="{show: form.get('email')?.errors && form.get('email')?.invalid && submit}" class="text-danger hide">
                                                               Valid Email is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label>Message</label>
                                                        <div class="form-group mb-4">
                                                            <textarea rows="6" formControlName="message"
                                                            [ngClass]="{'red-border': form.get('message')?.errors && submit}"
                                                            class="form-control pl-3"
                                                                placeholder="Write here…"></textarea>
                                                                <span  [ngClass]="{show: form.get('message')?.errors && form.get('message')?.invalid && submit}" class="text-danger hide">
                                                                    Message is required.
                                                                 </span>
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label>CV Attachment</label>
                                                        
                                                        <div class="input-group mb-3">
                                                            <div class="custom-file">
                                                                
                                                              <input type="file" class="custom-file-input"  formControlName="importFile" (change)="uploadFile($event)" #file  id="importFile">
                                                              
                                                              <label class="custom-file-label" for="importFile"  [ngClass]="{'red-border': !fileName && submit}" aria-describedby="inputGroupFileAddon02">{{fileName}}
                                                                <i class="fas fa-paperclip" *ngIf="!fileName"></i>
                                                                <span *ngIf="!fileName">Select</span>
                                                            </label>
                                                            </div>
                                                        </div>
                                                        <span  [ngClass]="{show: !fileName && submit}" class="text-danger hide">
                                                            Valid Document is required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <button
                                                            class="theme-btn theme-btn bg-theme-yellow-btn mt-2" [disabled]="btnDisable" [ngClass]="{opacity: btnDisable}" >Send Message</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    
</div>